@media only screen and (min-width: 600px) {
    .profile-stat-section {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
    .profile-stat {
        justify-content: space-between;
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 10px;
    }

    .sceneGrid {
        width: 90%;
        margin-left: 5%;
    }

    .gretLogo {
        width: 7vh;
        height: 7vh
    }

    .profiletext {
        margin-left: 80%;
        width: 15%;
        text-align: right;
    }

    .ProfileImageGrid {
        display: grid;
        width: 80%;
        margin-left: 20%;
        grid-template-columns: 70% 30%;
    }

    .SceneFavouriteGrid {
        display: grid;
        width: 60%;
        margin-left: 20%;
        grid-template-columns: 50% 50%;
    }

    .profile-section {
        height: 85vh;
        overflow: auto;
    }
}

@media only screen and (max-width: 600px) {
    .profile-stat-section {
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
    }
    .profile-stat {
        justify-content: space-between;
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 10px;
        border-bottom: 1px solid var(--new-ui-border);
        border-top: 1px solid var(--new-ui-border);
    }
    .hideBanner {
        display: none;
    }

    .gretLogo {
        width: 8vh;
        height: 8vh
    }

    .profiletext {
        display: flex;
        margin-right: 5%;
        width: 15%;
        text-align: right;
    }

    .ProfileImageGrid {
        display: grid;
        width: 60%;
        margin-left: 20%;
        grid-template-columns: 50% 50%;
    }

    .SceneFavouriteGrid {
        display: grid;
        width: 60%;
        margin-left: 20%;
        grid-template-columns: 50% 50%;
    }

    .profile-section {
        max-height: 50vh;
        overflow: auto;
    }
}

.gret-input-line {
    background-color: inherit;
    border: none;
    border-bottom-color: var(--new-ui-border);
    border-bottom-width: 1px;
    border-bottom-style: solid;
    color: white;
}

.gret-input-line[type=text]:focus {
    background-color: inherit;
    border: none;
    outline: none;
    color: white;
    border-bottom-color: var(--new-ui-border);
    border-bottom-width: 1px;
    border-bottom-style: solid;
}

.hrStyle {
    border: 0;
    border-top: 2px solid var(--off-white-1);
    width: 100%;
    margin: 0 !important;
}